<template>
  <div>
    <v-layout align-center justify-end class="pb-3 pa-1">
      <enroll-wallet :refresh="refresh" />
    </v-layout>

    <v-data-table :headers="headers" :items="roots" item-key="name" class="elevation-3" hide-actions>
      <template slot="items" slot-scope="props">
        <tr>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.make }}</td>
          <td class="monospace">{{ props.item.path }}</td>
          <td>{{ props.item.assetTag }}</td>
          <!--<td class="text-xs-left">-->
          <!--<v-tooltip bottom>-->
          <!--<template slot="activator">-->
          <!--<span  class="monospace">{{ truncate(props.item.xpub) }}</span>-->
          <!--</template>-->
          <!--<span>{{ props.item.xpub }}</span>-->
          <!--</v-tooltip>-->
          <!--</td>-->
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import TrezorConnect from 'trezor-connect';

import { getLedgerXPub } from '../../utils/bridge';
import EnrollWallet from './EnrollWallet';

export default {
  components: { EnrollWallet },
  props: [],
  data() {
    return {
      roots: [],
      dialog: false,
      walletName: '',
      rootPath: "m/48'/0'",
      headers: [
        {
          text: this.$t('_name'),
          align: 'left',
          value: 'name',
        },
        {
          text: this.$t('_deviceMake'),
          align: 'left',
          value: 'make',
        },
        {
          text: this.$t('_rootPath'),
          align: 'left',
          value: 'rootPath',
        },
        {
          text: this.$t('_assetTag'),
          align: 'left',
          value: 'assetTag',
        },

        // {
        //   text: this.$t("_address"),
        //   align: "left",
        //   value: "name"
        // }
      ],
    };
  },
  apollo: {
    roots: {
      query: gql`
        query roots($orgId: ID!) {
          roots(orgId: $orgId) {
            id
            path
            make
            name
            assetTag
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  methods: {
    truncate(str) {
      return str.slice(0, 7) + '...' + str.slice(-7);
    },
    saveRoot(hd, path, make, name) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        root: {
          hd,
          path,
          make,
          name,
        },
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $root: RootInput!) {
              createRoot(orgId: $orgId, root: $root) {
                id
              }
            }
          `,
          variables: vars,
        })
        .then(() => {
          this.refresh();
        });
    },
    refresh() {
      this.$apollo.queries.roots.refetch();
    },
    enrollLedger() {
      getLedgerXPub(this.rootPath).then((xpub) => {
        console.log(xpub);
        this.saveRoot(xpub, this.rootPath, 'Ledger', this.walletName);
      });
    },
    enrollTrezor() {
      // TrezorConnect.setCurrency("BTC");
      // -> MyEtherWallet default = Ethereum (m/44'/60'/0'/0)
      TrezorConnect.setCurrency('Testnet');
      const path = "m/48'/0'";
      TrezorConnect.getAccountInfo(path, async (result) => {
        if (result.success) {
          this.saveRoot(result.xpub, result.serializedPath, 'Trezor', 'TrezorWallet');
        } else {
          console.error('Error:', result.error); // error message
        }
      });
    },
  },
  mounted() {
    // this.updateRoots();
  },
};
</script>

<style>
.monospace {
  font-family: 'Courier New', monospace;
}
</style>
