/* File was migrated from JS, ts-comments are required for some sections */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import AppBtc from '@ledgerhq/hw-app-btc';
import TransportU2F from '@ledgerhq/hw-transport-u2f';
import * as BIP32 from 'bip32';
import bippath from 'bip32-path';
import { crypto } from 'bitcoinjs-lib';
import createHash from 'create-hash';
import { compose, dropLast, last } from 'ramda';
const rmd160 = () => createHash('ripemd160');

export function isElectron(): boolean {
  return 'Bridge' in window;
}

export async function getPublicKey(path: string): Promise<any> {
  if (isElectron()) {
    return (window as any).Bridge.getPublicKey(path);
  } else {
    throw new Error('Not running in electron');
  }
}

const getParentPath = compose(
  (array: any) => bippath.fromPathArray(array).toString(),
  dropLast(1),
  (path: any) => bippath.fromString(path).toPathArray() as any
);

export async function getLedgerXPub(path: string): Promise<string> {
  // rate limit calls to costly hardware interaction
  const p = new Promise<string>((resolve) => {
    TransportU2F.create().then(async (transport) => {
      const btc = new AppBtc(transport);
      const xpub = await getConnectedLedgerXPub(btc, path);
      console.log(xpub);
      resolve(xpub);
    });
  });

  return p;

  // if (isElectron()) {
  //   const req = {
  //     rootPath: path,
  //     verify: false,
  //     segwit: false
  //   };
  //
  //   return window.Bridge.getLedgerXPub(req);
  // } else {
  //   throw new Error("Not running in electron");
  // }
}

export const getConnectedLedgerXPub = async (appBtc: AppBtc, path: string): Promise<string> => {
  const parentPath = getParentPath(path);
  const child = await appBtc.getWalletPublicKey(path);
  const parent = await appBtc.getWalletPublicKey(parentPath);
  return createXPUB(path, child, parent);
};

const createXPUB = (path: string, child: any, parent: any) => {
  const pathArray = bippath.fromString(path).toPathArray();
  const pkChild = compressPublicKey(Buffer.from(child.publicKey, 'hex'));
  const pkParent = compressPublicKey(Buffer.from(parent.publicKey, 'hex'));
  const hdnode = BIP32.fromPublicKey(pkChild, Buffer.from(child.chainCode, 'hex'));
  hdnode.parentFingerprint = fingerprint(pkParent);
  hdnode.depth = pathArray.length;
  hdnode.index = Number(last(pathArray));

  return hdnode.toBase58();
};

const fingerprint = (publickey: Buffer) => {
  const pkh = rmd160().update(crypto.sha256(publickey)).digest();
  return ((pkh[0] << 24) | (pkh[1] << 16) | (pkh[2] << 8) | pkh[3]) >>> 0;
};

const compressPublicKey = (publicKey: Buffer) => {
  const prefix = (publicKey[64] & 1) !== 0 ? 0x03 : 0x02;
  const prefixBuffer = Buffer.alloc(1);
  prefixBuffer[0] = prefix;
  return Buffer.concat([prefixBuffer, publicKey.slice(1, 1 + 32)]);
};

export async function signTransaction(req: any) {
  const p = new Promise((resolve) => {
    TransportU2F.create().then(async (transport) => {
      const btc = new AppBtc(transport);
      const signature = await btc.signP2SHTransaction(
        req.inputs,
        // @ts-ignore
        req.paths,
        req.outputScript
      );
      resolve({ signature });
    });
  });
  return p;

  // inputs, paths, outputScript, lockTime, sigHashType, segwit) {
  // if (isElectron()) {
  //   return window.Bridge.signTransaction(req); //inputs, paths, outputScript, lockTime, sigHashType, segwit);
  // } else {
  //   throw new Error("Not running in electron");
  // }
}

// // wait for 1min secs for the ledger to be plugged in && unlocked etc
// export async function isLedgerConnected() {
//   const transport = await Transport.create();
//   const btc = new AppBtc(transport);
//   btc.get
//   if (isElectron()) {
//     return await window.Bridge.isLedgerConnected();
//   } else {
//     throw new Error("Not running in electron");
//   }
// }

export async function isLedgerConnected() {
  return new Promise((resolve) => {
    // rate limit calls to costly hardware interaction
    TransportU2F.create()
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });

    //   setTimeout(() => {
    //     const devices = HID.devices();
    //     if (_.find(devices, isLedgerDevice)) {
    //       resolve(true);
    //     } else {
    //       resolve(false);
    //     }
    //   }, 2000);
    //
    // });
  });
}

// function isLedgerDevice(device: HID.Device) {
//   return device.vendorId === LedgerManufacturerId &&
//     device.manufacturer &&
//     device.manufacturer.toLowerCase().includes("ledger");
// }
