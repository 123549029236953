<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="dialog = true"
    small
    btnColor="success"
    :btnName="$t('_enrollWallet')"
    :title="$t('_enrollWallet')"
  >
    <v-alert :value="errors" type="warning">{{ errors }}</v-alert>
    <v-stepper v-model="currentStep" vertical>
      <v-stepper-step :complete="currentStep > 1" step="1"> Select Wallet Type </v-stepper-step>

      <v-stepper-content step="1">
        <v-select
          :items="walletTypes"
          label="Select Hardware Wallet Type"
          v-model="walletType"
          item-text="name"
          item-value="id"
          persistent-hint
        ></v-select>
        <v-layout row wrap v-if="walletType === 'Ledger'">
          <v-flex xs12>
            Please plug in your ledger now, and make sure you don't have any other ledger software (like ledger live)
            running.
          </v-flex>
          <v-flex xs2>
            <v-btn @click="tryConnectLedger" :loading="ledgerConnecting">Connect</v-btn>
          </v-flex>
        </v-layout>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2"> Details </v-stepper-step>
      <v-stepper-content step="2">
        <v-form ref="step2" v-model="step2Valid">
          <v-text-field
            label="Device Name / Description (e.g. Amy's Ledger)"
            v-model="name"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            label="Path (Don't change this unless you have a good reason)"
            v-model="path"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field label="Asset Tag (if using)" v-model="assetTag"></v-text-field>
          <v-btn :disabled="!step2Valid" @click="save">Enroll Device</v-btn>
        </v-form>
      </v-stepper-content>
    </v-stepper>
  </ba-dialog>
</template>

<script>
import AppBtc from '@ledgerhq/hw-app-btc';
// import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import TransportU2F from '@ledgerhq/hw-transport-u2f';
import gql from 'graphql-tag';
import TrezorConnect from 'trezor-connect';

import { getConnectedLedgerXPub, getLedgerXPub } from '../../utils/bridge';

export default {
  props: ['refresh'],
  data() {
    return {
      rules: {
        number: (value) => !isNaN(Number(value)) || 'Invalid Number',
        required: (value) => !!value || 'Required.',
      },
      currentStep: 1,
      step2Valid: false,
      name: '',
      path: "m/48'/0'",
      assetTag: '',
      walletType: null,
      walletTypes: [
        {
          id: 'Ledger',
          name: 'Ledger',
          logo: '/public/ledger.png',
        },
      ],
      ledgerConnecting: false,
      errors: null,
      dialog: false,
      walletName: '',
      rootPath: "m/48'/0'",
      getHD: null,
    };
  },
  methods: {
    async tryConnectLedger() {
      this.ledgerConnecting = true;
      try {
        // const sub = TransportU2F.listen(m => {
        //   console.log("ledger message: ", m);
        //   // next page or error
        // });

        // const transport = await TransportWebUSB.create();
        const transport = await TransportU2F.create();

        // console.log("sub: ", sub);
        const btc = new AppBtc(transport);
        const pk = await btc.getWalletPublicKey('48/0');
        console.log('test pk: ', pk);
        this.ledgerConnecting = false;
        this.currentStep = 2;
        this.getHD = async (path) => {
          const xpub = await getConnectedLedgerXPub(btc, path);
          return xpub;
        };
        this.errors = null;
      } catch (error) {
        this.errors =
          'Problem connecting to ledger. Make sure it is plugged in, unlocked, and the Bitcoin App is open. ' +
          'Click connect to retry. ' +
          'Sometimes you must quit the Bitcoin app and restart it (on the ledger).';
        console.log('Problem connecting to ledger: ', error);
        this.ledgerConnecting = false;
      }
    },
    async save() {
      const hd = await this.getHD(this.path);
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        root: {
          hd,
          path: this.path,
          make: this.walletType,
          name: this.name,
          assetTag: this.assetTag,
        },
      };

      await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $root: RootInput!) {
            createRoot(orgId: $orgId, root: $root) {
              id
            }
          }
        `,
        variables: vars,
      });
      this.refresh();
      this.reset();
      this.dialog = false;
    },
    enrollLedger() {
      getLedgerXPub(this.rootPath).then((xpub) => {
        this.saveRoot(xpub, this.rootPath, 'Ledger', this.walletName);
      });
    },
    enrollTrezor() {
      // TrezorConnect.setCurrency("BTC");
      // -> MyEtherWallet default = Ethereum (m/44'/60'/0'/0)
      TrezorConnect.setCurrency('Testnet');
      const path = "m/48'/0'";
      TrezorConnect.getAccountInfo(path, async (result) => {
        if (result.success) {
          try {
            const vars = {
              orgId: this.$store.state.currentOrg.id,
              root: {
                hd: result.xpub,
                path: result.serializedPath,
                make: 'Trezor',
                name: 'TrezorWallet',
              },
            };

            console.log('Account ID: ', result.serializedPath);
            console.log('Serialized path: ', result.xpub);

            await this.$apollo.mutate({
              // Query
              mutation: gql`
                mutation ($orgId: ID!, $root: RootInput!) {
                  createRoot(orgId: $orgId, root: $root) {
                    id
                  }
                }
              `,
              variables: vars,
            });
            this.refresh();
            this.reset();
            this.dialog = false;
          } catch (e) {
            console.error('Error adding Root');
          }
        } else {
          console.error('Error:', result.error); // error message
        }
      });
    },
    reset() {
      this.name = '';
      this.getHD = null;
      this.path = "m/48'/0'";
      this.currentStep = 1;
      this.assetTag = '';
      this.walletType = null;
    },
  },
};
</script>

<style>
.monospace {
  font-family: 'Courier New', monospace;
}
</style>
