<template>
  <v-container fluid grid-list-xl>
    <v-card style="background: white" class="elevation-3">
      <v-card-text color="white">
        <v-subheader class="pa-0 text-uppercase">{{ $t('_emailPreferences') }}</v-subheader>
        <p>
          <v-switch
            v-model="me.emailContactPreferences"
            value="OnNewTaskAssigned"
            :label="$t('_receiveEmailOnNewAssignedTask')"
            v-on:change="emailPreferencesUpdated"
          ></v-switch>
        </p>
      </v-card-text>
    </v-card>
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="4000" :top="true">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

export default {
  props: [],
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      me: {
        emailContactPreferences: [],
      },
    };
  },
  apollo: {
    me() {
      return {
        query: gql`
          query getUser {
            me {
              emailContactPreferences
            }
          }
        `,
      };
    },
  },
  methods: {
    popSnackbar(message, color) {
      this.snackbarColor = color;
      this.snackbarText = message;
      this.snackbar = false;
      this.snackbar = true;
    },
    emailPreferencesUpdated: _.debounce(function () {
      this.popSnackbar(this.$t('_savingInProgress'), 'info');
      this.saveContactPreferences()
        .then(() => {
          this.popSnackbar(this.$t('_contactPreferencesUpdated'), 'success');
        })
        .catch((err) => {
          this.popSnackbar(this.$t('_contactPreferencesNotUpdated'), 'error');
          console.error(err);
        });
    }, 1000),
    async saveContactPreferences() {
      console.log(this.me.emailContactPreferences);
      this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($userId: ID!, $user: UserInput!) {
            updateUser(userId: $userId, user: $user) {
              id
            }
          }
        `,
        // Parameters
        variables: {
          userId: this.$store.state.user.id,
          user: {
            emailContactPreferences: this.me.emailContactPreferences,
          },
        },
      });
    },
  },
};
</script>

<style>
label {
  text-transform: none;
}
</style>
